<template>
  <div v-if="viewList" class="card-skeleton-grid">
    <b-card>
      <b-card-body>
        <div class="d-flex quiz-title">
          <b-skeleton-img class="mr-2" width="32px" />
          <div class="d-block w-100">
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </div>
        </div>
        <div class="data-divider" />
        <div class="d-flex justify-content-between">
          <b-skeleton animation="wave" width="40%" class="big-text"></b-skeleton>
          <b-skeleton animation="wave" width="20%" class="big-text"></b-skeleton>
        </div>
        <div class="d-flex justify-content-between quiz-info">
          <b-skeleton animation="wave" width="30%"></b-skeleton>
          <b-skeleton animation="wave" width="25%"></b-skeleton>
        </div>
      </b-card-body>
    </b-card>
  </div>
  <div v-else class="card-skeleton-list">
    <b-card>
      <b-card-body>
        <div class="d-flex justify-content-between align-items-start">
          <div class="d-block w-100">
            <div class="d-flex quiz-title">
              <b-skeleton-img class="mr-2" width="35px" />
              <div class="d-block w-100">
                <b-skeleton animation="wave" width="70%"></b-skeleton>
              </div>
            </div>
            <div class="d-flex align-items-center quiz-info">
              <b-skeleton animation="wave" width="30%" class="big-text"></b-skeleton>
              <b-skeleton animation="wave" width="15%" class="big-text"></b-skeleton>
              <b-skeleton animation="wave" width="15%" class="big-text"></b-skeleton>
            </div>
          </div>
          <b-skeleton animation="wave" width="15%" class="big-text"></b-skeleton>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
export default {
  props: {
    viewList: {
      type: Boolean,
      default: true
    }
  }
}

</script>
<style lang="scss" scoped>
  .card {
    padding: .8125rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
    @media (max-width: 576px) {
      padding: 0.5rem;
    }
  }
  .card-body {
    padding: 0;
  }
  .b-skeleton-img {
    border-radius: .5rem;
  }
  .b-skeleton-text {
    height: 1rem;
    border-radius: 0;
  }
  .card-skeleton-grid {
    .card {
      height: 9.238rem;
      @media (max-width: 1366px) {
        height: 10.2rem;
      }
      @media (max-width: 992px) {
        height: 9.2rem;
      }
      @media (max-width: 576px) {
        height: 8.4rem;
      }
    }
    .quiz-title {
      margin-bottom: 0.85rem;
      @media (max-width: 576px) {
        margin-bottom: 0.55rem;
      }
      .b-skeleton-text {
        height: 35px;
        margin: 0;
      }
    }
    .b-skeleton-img {
      height: 36px;
    }
    .big-text {
      height: 2rem;
      margin-top: 0.8rem;
      margin-bottom: 0.5rem;
      @media (max-width: 576px) {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }
  }
  .card-skeleton-list {
    .card {
      height: 6.813rem;
      @media (max-width: 576px) {
        height: 6.5rem;
      }
    }
    .b-skeleton-text {
      height: 1.05rem;
      @media (max-width: 576px) {
        height: 1.3rem;
      }
    }
    .b-skeleton-img {
      height: 40px;
    }
    .quiz-title {
      margin-bottom: 0.9rem;
      @media (max-width: 576px) {
        margin-bottom: 0.6rem;
      }
      .b-skeleton-text {
        height: 39px;
        margin: 0;
      }
    }
    .quiz-info {
      margin-top: 0.3rem;
      .big-text {
        margin-right: 1.5rem;
      }
      .b-skeleton-text {
        @media (max-width: 576px) {
          margin-right: 1rem;
        }
      }
    }
    .big-text {
      height: 1.75rem;
      @media (max-width: 576px) {
        height: 1.85rem;
      }
    }
  }
  .data-divider {
    height: 1px;
    background-color: #e6e6e6;
  }
</style>
