<template>
  <div class="library-default" v-if="listData">
    <div class="sub-title">Danh sách video</div>
    <div class="list-video">
      <NoData v-if="!listData.length" />
      <VideoItem v-for="item in listData" :key="item.id" :item="item"/>
    </div>
  </div>
</template>

<script>
import VideoItem from '~/components/mlearn/video/info.vue'
export default {
  components: {
    VideoItem
  },
  props: {
    listData: {
      type: Array,
      default () {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .library-default{
    .list-video{
      background: var(--white);
      border: 1px solid #CED4DA;
      border-radius: 8px;
      padding:16px;
    }
  }
</style>
