<template>
  <div class="topic-video-relation" v-if="listData.length">
    <div class="title">{{title}}</div>
    <div class="slider">
      <client-only>
        <VueSlickCarousel v-bind="settings">
          <VideoItem v-for="(item, ind) in listData" :key="item.id" :item="item" :class="{'video-current': ind === settings.initialSlide}" :only-name="true" />
          <template #prevArrow>
            <ArrowLeft />
          </template>
          <template #nextArrow>
            <ArrowRight />
          </template>
        </VueSlickCarousel>
      </client-only>
    </div>
  </div>
</template>

<script>
import Resource from '~/common/api/resource'
import VideoItem from '~/components/mlearn/video/item.vue'
const videoResource = new Resource('library/file/related')
const relationResource = new Resource('library/file/involve')
export default {
  components: {
    VideoItem
  },
  props: {
    id: {
      type: String,
      default () {
        return ''
      }
    },
    title: {
      type: String,
      default () {
        return 'Video cùng danh sách'
      }
    },
    type: {
      type: String,
      default () {
        return 'related'
      }
    }
  },
  data () {
    return {
      listData: [],
      settings: {
        dots: false,
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              dots: true
            }
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 2.5
            }
          }
        ]
      }
    }
  },
  async fetch () {
    let data
    const query = { id: this.id, limit: 10 }
    if (this.type === 'related') {
      data = await relationResource.list(query)
    } else {
      data = await videoResource.list(query)
      let index = data.data.findIndex(it => it.id === this.id)
      if (index < 0) {
        index = 0
      }
      this.settings.initialSlide = index
    }
    this.listData = data.data
  }
}
</script>

<style lang="scss">
  .topic-video-relation{
    .title{
      font-weight: bold;
      font-size: 24px;
      @media (max-width:1366px) {
        font-size: 18px;
      }
      @media (max-width:576px) {
        font-size: 16px;
      }
    }
    .slick-slider{
      .slick-list{
        margin: 0 -10px;
        .slick-track{
          .slick-slide{
            padding: 10px;
            @media (max-width:1366px) {
              padding: 10px 5px;
            }
            @media (max-width:576px) {
              padding: 10px 4px;
            }
          }
        }
      }
    }
  }
</style>
