<template>
  <div class="ml-library-collections" v-if="listData">
    <div class="container">
      <div class="row no-gutters align-items-center">
        <div class="col-6 col-lg-8 sub-title">Nội dung</div>
        <div class="col-6 col-lg-4">
          <el-select v-if="listData.length > 1" class="w-100" v-model="topic_id" placeholder="Tất cả các tiết học" popper-class="ml-library-collections-topic" @change="findTopic()">
            <el-option label="Tất cả các tiết học" :value="null"></el-option>
            <el-option v-for="(collection, ind) in listData" :key="collection.id" :label="collection.name" :value="ind"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <el-collapse class="custom-collapse" v-model="activeName">
      <el-collapse-item v-for="(collection,index) in listData" :key="collection.id" :name="index">
        <template slot="title">
          <div class="collection-name">{{ collection.name }} <span class="duration-topic px-2">({{ collection.total_duration_file | durationTopic }})</span></div>
          <!-- <span class="duration-topic px-2">({{ collection.total_duration_file | durationTopic }})</span> -->
        </template>
        <NoData v-if="!collection.files.length" />
        <VideoItem v-for="item in collection.files" :key="item.id" :item="item"/>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
import VideoItem from '~/components/mlearn/video/info.vue'
export default {
  components: {
    VideoItem
  },
  props: {
    listData: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      activeName: [],
      topic_id: null
    }
  },
  created () {
    this.activeTopic()
  },
  methods: {
    activeTopic () {
      const storeData = this.$store.state.storeRedirect
      if (storeData && storeData.collection_id) {
        const topic = this.listData.find(t => t.id === storeData.collection_id)
        if (topic) {
          this.activeName = [this.listData.indexOf(topic)]
        }
        this.$store.dispatch('setStoreRedirect', null)
      } else {
        this.listData.forEach((item, index) => {
          this.activeName.push(index)
        })
      }
    },
    findTopic () {
      if (this.topic_id === null) {
        this.listData.forEach((item, index) => {
          this.activeName.push(index)
        })
        return
      }
      this.activeName = [this.topic_id]
    }
  }
}
</script>

<style lang="scss">
  .ml-library-collections{
    .el-select{
      .el-input{
        input{
          &::placeholder{
            font-size: 14px;
          }
        }
      }
    }
    .custom-collapse{
      .el-collapse-item{
        margin-bottom: 10px;
        background: var(--white);
        border: 1px solid #CED4DA;
        border-radius: 8px;
        padding:16px;
        @media (max-width:1366px) {
          border: 0;
          border-radius: 0;
          padding:10px 5px 10px 10px;
        }
        .el-collapse-item__header{
          padding:0;
          padding-right:5px;
          font-weight: 500;
          border-radius: 8px 8px 0 0;
          border:0;
          height: auto;
          line-height: unset;
          display: flex !important;
          display: -webkit-flex !important;
          flex-flow: row wrap;
          font-size: 16px;
          @media (max-width:1366px) {
            font-size: 14px;
            padding-right:0;
          }
          .collection-name{
            flex: 1 1 0px;
          }
          .duration-topic{
            font-weight: normal;
            color: #828282;
            font-size: 14px;
          }
          .el-icon-arrow-right{
            transform: rotate(90deg);
            &.is-active{
              transform: rotate(-90deg);
            }
          }
        }
        .el-collapse-item__wrap{
          border: 0;
          .el-collapse-item__content{
            padding-bottom: 0;
            .item{
              background: var(--white);
              border-top: 1px solid #F0F2F5;
              box-sizing: border-box;
              padding:12px 5px;
              display: inline-flex;
              width: 100%;
              align-items: center;
              @media (max-width:576px) {
                padding:10px 5px;
              }
              &:first-child{
                border-top: 0;
                padding-top: 20px;
                @media (max-width:1366px) {
                  padding-top: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
  .ml-library-collections-topic{
    max-width: 300px;
  }
</style>
