<template>
  <div v-if="viewList" class="card-skeleton-grid">
    <b-card>
      <b-card-body>
        <div class="d-flex mb-2">
          <b-skeleton-img class="mr-2" width="31.05px" />
          <div class="d-block w-100 document-title">
            <b-skeleton animation="wave" width="100%"></b-skeleton>
          </div>
        </div>
        <div class="data-divider" />
        <div class="d-flex justify-content-between document-info">
          <b-skeleton animation="wave" width="40%" class="big-text"></b-skeleton>
          <b-skeleton animation="wave" width="20%" class="big-text"></b-skeleton>
        </div>
      </b-card-body>
    </b-card>
  </div>
  <div v-else class="card-skeleton-list">
    <b-card>
      <b-card-body>
        <div class="d-flex justify-content-between align-items-center">
          <div class="d-block w-100">
            <div class="d-flex mb-2">
              <b-skeleton-img class="mr-2" width="25px" />
              <div class="d-block w-100 document-title">
                <b-skeleton animation="wave" width="70%"></b-skeleton>
              </div>
            </div>
            <b-skeleton animation="wave" width="30%" class="big-text class"></b-skeleton>
          </div>
          <b-skeleton animation="wave" width="15%" class="big-text"></b-skeleton>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
export default {
  props: {
    viewList: {
      type: Boolean,
      default: true
    }
  }
}

</script>
<style lang="scss" scoped>
  .card {
    padding: .8125rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
  }
  .card-body {
    padding: 0;
  }
  .b-skeleton-img {
    border-radius: .3rem;
  }
  .b-skeleton-text {
    border-radius: 0;
  }
  .card-skeleton-grid {
    .card {
      height: 7.55rem;
    }
    .document-title {
      .b-skeleton-text {
        height: 41px;
        margin: 0;
        @media (max-width: 576px) {
          height: 40px;
        }
      }
    }
    .big-text {
      height: 2rem;
      margin-top: 0.7rem;
      @media (max-width: 576px) {
        margin-top: 0.5rem;
      }
    }
    .b-skeleton-img {
      height: 40px;
    }
  }
  .card-skeleton-list {
    .card {
      height: 6.7rem;
      @media (max-width: 576px) {
        height: 6.9rem;
      }
    }
    .b-skeleton-text {
      height: 1.05rem;
    }
    .b-skeleton-img {
      height: 33px;
    }
    .document-title {
      .b-skeleton-text {
        height: 33px;
        margin: 0;
      }
    }
    .big-text {
      height: 1.75rem;
      @media (max-width: 576px) {
        height: 1.9rem;
      }
      &.class {
        margin-top: 1.15rem;
        @media (max-width: 576px) {
          margin-top: 1rem;
        }
      }
    }
  }
  .data-divider {
    height: 1px;
    background-color: #e6e6e6;
  }
</style>
