<template>
  <div v-if="viewList" class="card-skeleton-grid">
    <b-card no-body img-top>
      <b-skeleton-img card-img="top" aspect="327:193"></b-skeleton-img>
      <b-card-body>
        <b-skeleton animation="wave" width="100%" class="topic-title"></b-skeleton>
        <b-skeleton animation="wave" width="80%" class="topic-info teacher"></b-skeleton>
        <b-skeleton animation="wave" width="70%" class="topic-info d-none d-md-block d-lg-block"></b-skeleton>
        <b-skeleton animation="wave" width="55%" class="topic-info"></b-skeleton>
      </b-card-body>
    </b-card>
  </div>
  <div v-else class="card-skeleton-list">
    <b-card no-body img-left>
      <b-skeleton-img card-img="left" width="16.8rem" aspect="327:193"></b-skeleton-img>
      <b-card-body>
        <b-skeleton animation="wave" width="80%" class="topic-title"></b-skeleton>
        <b-skeleton animation="wave" width="50%" class="topic-info teacher"></b-skeleton>
        <b-skeleton animation="wave" width="40%" class="topic-info d-none d-md-block d-lg-block"></b-skeleton>
        <b-skeleton animation="wave" width="30%" class="topic-info"></b-skeleton>
      </b-card-body>
    </b-card>
  </div>
</template>
<script>
export default {
  props: {
    viewList: {
      type: Boolean,
      default: true
    }
  }
}

</script>
<style lang="scss" scoped>
  .card {
    border-radius: 0.5rem;
    box-shadow: 0px 4px 1rem rgba(0, 0, 0, 0.1);
    border-color: #eaeaea;
  }
  .b-skeleton-text {
    border-radius: 0;
  }
  .card-skeleton-grid {
    padding: 0 10px;
    .card-body {
      height: 10.5rem;
      padding: .75rem;
      @media (max-width: 576px) {
        height: 7.7rem !important;
      }
    }
    .topic-title {
      height: 3rem;
      margin-bottom: 0.75rem;
      @media (max-width: 1366px) {
        margin-bottom: 0.5rem;
      }
      @media (max-width: 576px) {
        height: 1.375rem;
      }
    }
    .topic-info {
      margin-bottom: 0.625rem;
      height: 1.15rem;
      @media (max-width: 576px) {
        height: 1.3rem;
      }
      &.teacher {
        height: 1.5rem;
        @media (max-width: 576px) {
          height: 1.75rem;
        }
      }
    }
  }
  .card-skeleton-list {
    .card-body {
      height: 8.438rem;
      padding: .825rem;
      @media (max-width: 576px) {
        height: 6.75rem !important;
      }
    }
    .b-skeleton-img {
      border-radius: .5rem;
      @media (max-width: 576px) {
        width: 10rem !important;
      }
    }
    .topic-title {
      height: 1.25rem;
      margin-bottom: 0.75rem;
      @media (max-width: 1366px) {
        margin-bottom: 0.5rem;
      }
    }
    .topic-info {
      margin-bottom: 0.625rem;
      height: 1.25rem;
      &.teacher {
        height: 1.5rem;
      }
    }
  }
</style>
