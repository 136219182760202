<template>
  <div class="no-data-content text-center">
    <img v-if="type === 'attach'" src="~/assets/icons/no-data/attach.svg" alt="">
    <img v-if="type === 'comment'" src="~/assets/icons/no-data/comment.svg" alt="">
    <img v-if="type === 'video'" src="~/assets/icons/no-data/video.svg" alt="">
    <div class="text">{{text}}</div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'attach',
      required: false
    },
    text: {
      type: String,
      default: 'Chưa có bài tập ôn luyện nào',
      required: false
    }
  }

}
</script>

<style lang="scss" scoped>
  .no-data-content{
    padding: 47px 0;
    @media (max-width:576px) {
      padding: 30px 0;
    }
    .text{
      font-weight: 400;
      color: #888888;
      font-size: 16px;
      padding-top: 16px;
      @media (max-width:576px) {
        font-size: 14px;
        padding-top: 14px;
      }
    }
  }
</style>
