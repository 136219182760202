<template>
  <div class="ml-view-video" v-if="item">
    <!-- <div class="video-name font-weight-bold">{{item.topic.name}}</div> -->
    <!-- <div class="list-info d-flex align-items-center mb-3">
      <div class="info mr-3">
        <img src="~/mlearn/icons/video/number-video.svg" class="icon" alt="time" />
        {{ item.topic.number_video }} Video
      </div>
      <div class="info mr-3">
        <img src="~/mlearn/icons/video/time-video.svg" class="icon" alt="time" />
        {{ item.duration | durationTopic}}
      </div>
      <div class="info" v-if="item.teachers.length">
        <img src="~/mlearn/icons/video/teacher.svg" class="icon" alt="teacher" />
        Giáo viên: <span class="text-primary">{{ listTeacher }}</span>
      </div>
    </div> -->
    <div class="form-view">
      <div class="ratio-custom">
        <div class="content">
          <template v-if="backdrop">
            <div class="backdrop"></div>
            <img class="action-play-video" src="~/mlearn/icons/video/play.svg" @click="playVideo()" />
          </template>
          <video height="auto" width="100%" ref="videoPlayer" class="video-js vjs-custom-skin vjs-big-play-centered"></video>
        </div>
      </div>
      <div class="video-info">
        <div class="row">
          <div class="col-sm-9 order-sm-0">
            <div class="video-title">{{item.name}}</div>
          </div>
          <div class="col-sm-3 order-sm-1 pointer">
            <div class="d-flex align-items-center justify-content-end">
              <span class="report-video-action d-flex align-items-center" @click="showReport = true">
                <img src="~/mlearn/icons/video/report.svg" alt="report" class="icon report-video mr-2"/> Báo lỗi
              </span>
              <span class="save-video-action d-flex align-items-center ml-3" :class="{'active text-primary': item.is_saved}" @click="openDropdown">
                <span class="mr-2" :class="{'active': item.is_saved}" @click="openDropdown" v-html="require('~/assets/mlearn/icons/video/save.svg?raw')"></span>
                {{ item.is_saved ? 'Bỏ lưu' : 'Lưu' }}
              </span>
            </div>
            <Playlist field="video_id" :item="item" :playlist="playlist" :open="open" @close="open = false" @addData="addData" @showError="showError" class-button="bottom-0 right-0" />
          </div>
        </div>
      </div>
    </div>
    <PlaylistNotify :flag-notify="flagNotify" :text-notify="textNotify" @hide="flagNotify = false" />
    <b-modal id="modal-report-video" size="sm" v-model="showReport" no-close-on-backdrop no-close-on-esc hide-footer hide-header centered>
      <img class="close pointer position-absolute" src="~/mlearn/icons/modal/close-modal.svg" alt="close" @click="showReport = false"/>
      <div class="form-report">
        <div class="header-report text-center">
          <img src="~/mlearn/icons/video/report.svg" alt="report" class="report" />
          <h2 class="font-weight-bold">Báo lỗi Video</h2>
        </div>
        <div class="line"></div>
        <div class="list-reason">
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="reason-1" name="reason" :value="1" v-model="error">
            <label class="custom-control-label" for="reason-1">Lỗi không xem được video</label>
          </div>
          <div class="custom-control custom-radio">
            <input type="radio" class="custom-control-input" id="reason-2" name="reason" :value="2" v-model="error">
            <label class="custom-control-label" for="reason-2">Lỗi khác</label>
          </div>
          <textarea class="reason-text" v-model="reasonText" placeholder="Vui lòng mô tả lại lỗi bạn gặp" v-if="error === 2"></textarea>
        </div>
        <div class="action">
          <button @click="reportVideo">Gửi báo lỗi</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
import 'videojs-contrib-quality-levels'
import videojsqualityselector from 'videojs-hls-quality-selector'
import PlaylistNotify from '~/components/mlearn/playlist/notify.vue'
import Playlist from '~/components/mlearn/playlist/index.vue'
import Resource from '~/common/api/resource'
const playlistResource = new Resource('library/playlist')
const playlistFileResource = new Resource('library/playlist/file')
const reportVideoResource = new Resource('library/video/report')
export default {
  components: {
    PlaylistNotify,
    Playlist
  },
  props: {
    item: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      backdrop: true,
      playlistId: '',
      processing: false,
      open: false,
      player: null,
      playlist: [],
      flagNotify: false,
      textNotify: '',
      options: {
        autoplay: false,
        controls: true,
        muted: false,
        preload: 'none', // auto,metadata,none
        language: 'vi',
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        sources: [{
          type: this.item.file_type || 'video/mp4',
          src: this.item.url
        }],
        poster: this.item.thumbnail,
        notSupportedMessage: 'Không thể phát video.',
        controlBar: {
          playToggle: true,
          volumePanel: {
            inline: false,
            vertical: true
          },
          currentTimeDisplay: true,
          timeDivider: true,
          durationDisplay: true,
          progressControl: true,
          liveDisplay: true,
          seekToLive: true,
          remainingTimeDisplay: true,
          customControlSpacer: true,
          playbackRateMenuButton: false,
          chaptersButton: true,
          descriptionsButton: true,
          subsCapsButton: true,
          audioTrackButton: true,
          fullscreenToggle: true,
          pictureInPictureToggle: true
        },
        fluid: true,
        currentTime: 0,
        aspectRatio: '16:9'
      },
      showReport: false,
      error: 1,
      reasonText: ''
    }
  },
  computed: {
    listTeacher () {
      return this.item.teachers.map((teacher) => { return teacher.name }).join(', ')
    },
    user () {
      return this.$store.state.user
    }
  },
  created () {
    this.loadPlayList()
  },
  mounted () {
    this.player = videojs(this.$refs.videoPlayer, this.options)
    this.player.hlsQualitySelector = videojsqualityselector
    this.player.hlsQualitySelector()
    this.player.on('ended', () => {
      this.backdrop = true
    })
  },
  beforeDestroy () {
    if (this.player) {
      this.player.dispose()
    }
  },
  methods: {
    addData (form) {
      const message = `Đã lưu vào  "<b>${form.playlist_name}</b>"`
      if (form.playlist_id) {
        delete form.playlist_name
      }
      if (this.processing) { return }
      this.processing = true
      playlistFileResource.store(form)
        .then((res) => {
          if (res.status === 200) {
            // eslint-disable-next-line vue/no-mutating-props
            this.item.is_saved = true
            this.open = false
            this.textNotify = message
            this.showNotify()
            this.loadPlayList()
          }
        })
        .finally(() => {
          this.processing = false
        })
    },
    UnSave () {
      if (this.processing) { return }
      this.processing = true
      playlistFileResource.destroy(this.item.id)
        .then((res) => {
          // eslint-disable-next-line vue/no-mutating-props
          this.item.is_saved = false
          this.flagNotify = true
          this.textNotify = 'Đã bỏ lưu video'
        })
        .finally(() => {
          this.processing = false
        })
    },
    async loadPlayList () {
      if (!this.user) { return }
      const { data } = await playlistResource.list()
      this.playlist = data
    },
    playVideo () {
      this.player.play()
      this.backdrop = false
    },
    reportVideo () {
      if (this.processing) { return }
      if (this.error !== 1 && !this.reasonText) {
        this.$notify({
          type: 'warning',
          message: 'Chưa nhập lý do báo lỗi'
        })
        return
      }
      this.processing = true
      const text = this.error === 1 ? 'Lỗi không xem được video' : (this.reasonText || 'Lỗi khác')
      const formData = {
        video_id: this.item.id,
        reason: text
      }
      reportVideoResource.store(formData)
        .then((res) => {
          this.showReport = false
          this.$notify({
            type: 'success',
            message: 'Gửi báo lỗi thành công'
          })
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.processing = false
        })
    },
    showError (error) {
      this.textNotify = error
      this.showNotify()
    },
    showNotify () {
      this.flagNotify = false
      this.flagNotify = true
    },
    openDropdown () {
      if (!this.user) { // chưa đăng nhập
        this.$router.push('/user/login')
        return
      }
      if (this.item.is_saved) {
        this.UnSave()
        return
      }
      this.open = true
    }
  }
}
</script>

<style lang="scss">
  .ml-view-video{
    .video-name{
      font-size: 28px;
      @media (max-width:1366px) {
        font-size: 20px;
      }
      @media (max-width:576px) {
        font-size: 18px;
      }
    }
    .list-info{
      .info{
        color: #888888;
        font-size: 16px;
        @media (max-width:1366px) {
          font-size: 15px;
        }
        @media (max-width:576px) {
          font-size: 14px;
        }
        img{
          width:12px;
          @media (max-width:576px) {
            width:auto;
          }
        }
      }
    }
    .form-view{
      background: var(--white);
      border-radius: 8px;
      .ratio-custom {
        background: var(--white);
        --aspect-ratio: 56.25%;
        .content {
          border:1px solid #ccc;
          border-radius: 8px;
          .video-js{
            border-radius: 8px;
            video, .vjs-poster{
              border-radius: 8px;
            }
            .vjs-control{
              width: 3em;
              @media (max-width:576px) {}
            }
            .vjs-control-bar{
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            }
            .vjs-big-play-button{
              display: none;
              .vjs-icon-placeholder{
                &::before{
                  content: '';
                  background-image: url("~/assets/mlearn/icons/video/play.svg");
                  background-repeat: no-repeat;
                  background-position-x: center;
                }
              }
            }
            .vjs-time-control{
              display:block;
              padding-left: 0.5rem;
              padding-right: 0.5rem;
              min-width: 0.5rem;
              @media (max-width:576px) {}
            }
            .vjs-time-divider{
              padding:0;
            }
            .vjs-quality-selector{
              .vjs-menu-content{
                flex-direction: column-reverse;
                display: flex;
                .vjs-menu-item{
                  text-transform: capitalize;
                }
              }
            }
            .vjs-progress-control{
              .vjs-progress-holder{
                background: rgba(255, 255, 255, 0.5);
                .vjs-load-progress{
                  background: rgba(255, 255, 255, 0.5);
                }
                .vjs-play-progress{
                  background: #004390;
                }
              }
            }
            .vjs-volume-panel{
              .vjs-volume-control{
                .vjs-volume-level{
                  background: #004390;
                }
              }
            }
          }
          .backdrop{
            position: absolute;
            z-index: 900;
            top:0;
            right:0;
            bottom:0;
            left:0;
            border-radius: 8px;
            background-color: rgba(0,0,0, 0.5);
          }
          .action-play-video{
            position: absolute;
            z-index: 990;
            top:50%;
            left:50%;
            transform: translate(-50%,-50%);
            cursor: pointer;
            width:70px;
            height:70px;
            @media (max-width:1366px) {
              width:60px;
              height:60px;
            }
            @media (max-width:576px) {
              width:50px;
              height:50px;
            }
          }
        }
      }
      .video-info{
        padding:15px 20px 24px 24px;
        @media (max-width:1366px) {
          padding:12px;
        }
        @media (max-width:576px) {
          padding:10px;
        }
        .video-title{
          font-weight: 600;
          font-size: 18px;
          @media (max-width:1366px) {
            font-size: 17px;
          }
          @media (max-width:576px) {
            font-size: 16px;
          }
        }
        .report-video-action{
          img{
            @media (max-width:576px) {
              width: 20px;
            }
          }
        }
        .save-video-action{
          svg{
            height: 26px;
            width: 20px;
            @media (max-width:576px) {
              height: 20px;
              width: 15px;
            }
          }
        }
      }
    }
  }
  #modal-report-video{
    &___BV_modal_outer_{
      z-index: 9999 !important;
    }
    .modal-dialog{
      max-width: 335px;
      .modal-body{
        position: relative;
        padding:24px;
        @media (max-width:1366px) {
          padding:18px;
        }
        @media (max-width:576px) {
          padding:16px;
        }
        .close{
          right: 12px;
          top: 12px;
        }
        .form-report{
          .header-report{
            h2 {
              font-size: 24px;
              margin:20px 0 18px;
              @media (max-width:1366px) {
                font-size: 18px;
                margin:15px 0 10px;
              }
              @media (max-width:576px) {
                font-size: 16px;
                margin:10px 0 9px;
              }
            }
          }
          .line{
            border-top: 1px solid #EEEEEE;
          }
          .list-reason{
            > div{
              padding-top: 16px;
              @media (max-width:1366px) {
                padding-top: 13px;
              }
              @media (max-width:576px) {
                padding-top: 10px;
              }
              input {
                margin-right: 20px;
                cursor: pointer;
                @media (max-width:576px) {}
              }
              label{
                cursor: pointer;
                font-style: normal;
                font-weight: normal;
                color: #333333;
                padding-top: 3px;
                font-size: 18px;
                padding-left: 20px;
                @media (max-width:1366px) {
                  font-size: 17px;
                  padding-left: 15px;
                }
                @media (max-width:576px) {
                  font-size: 16px;
                  padding-left: 10px;
                }
                &::before, &::after{
                  width: 20px;
                  height: 20px;
                  outline: none !important;
                  box-shadow: none !important;
                  border-color: #FFA216;
                }
              }
              .custom-control-input:checked {
                ~ .custom-control-label::before {
                  background-color: var(--white);
                }
                ~ .custom-control-label::after {
                  background-image: url('~/assets/mlearn/icons/video/circle-radio.png');
                }
              }
            }
            .reason-text{
              border: 1px solid #E9E9E9;
              border-radius: 12px;
              font-size: 12px;
              width:100%;
              margin-top: 10px;
              padding:10px;
              height:100px;
              @media (max-width:576px) {
                margin-top: 10px;
                padding:10px;
                height:60px;
              }
              &::placeholder{
                color: #888888;
              }
            }
          }
          .action{
            text-align: center;
            padding-top: 14px;
            button{
              background: #004390;
              font-weight: bold;
              color: var(--white);
              font-size: 16px;
              border-radius: 20px;
              padding:10px 20px;
              @media (max-width:576px) {
                font-size: 14px;
                border-radius: 15px;
                padding:10px 20px;
              }
            }
          }
        }
      }
    }
  }
</style>
